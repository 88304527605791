import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export const setSsrWarmupData = <T>(flowAPI: ControllerFlowAPI, key: string, data: T) => {
  if (flowAPI.environment.isSSR) {
    flowAPI.controllerConfig.wixCodeApi.window.warmupData.set(key, data)
  }
}

export const getSsrWarmupData = <T>(flowAPI: ControllerFlowAPI, key: string): T | undefined => {
  return flowAPI.environment.isSSR
    ? undefined
    : flowAPI.controllerConfig.wixCodeApi.window.warmupData.get(key)
}
