import {SupportedWixLocales, supportedWixlocales} from 'wix-design-systems-locale-utils'
import {useSettingsEnvironment} from '@wix/yoshi-flow-editor'
import memoize from 'lodash/memoize'
import {getWeekStartByLocale} from 'weekstart'

enum Locales {
  en = 'en',
  enNZ = 'en-NZ',
  enGB = 'en-GB',
  enBE = 'en-BE',
}

const parseLocaleFromBmRaw = (locale?: string): SupportedWixLocales => {
  if (!locale) {
    return Locales.en
  }

  // fixes en-gb to en-GB
  const [language, country] = locale.split('-')
  let fixedLocale = language

  if (country) {
    fixedLocale += `-${country.toUpperCase()}`
  }

  // en-NZ is not supported by WUT, covert it to en-GB
  if (fixedLocale.toLowerCase() === Locales.enNZ.toLowerCase()) {
    fixedLocale = Locales.enGB
  }
  // en-BE is not supported by WUT, covert it to en-GB
  if (fixedLocale.toLowerCase() === Locales.enBE.toLowerCase()) {
    fixedLocale = Locales.enGB
  }

  return (
    supportedWixlocales.includes(fixedLocale as SupportedWixLocales) ? fixedLocale : language
  ) as SupportedWixLocales
}

const parseLocaleFromBM = memoize(parseLocaleFromBmRaw)

export {parseLocaleFromBM}

export type MultilingualContext = ReturnType<typeof useSettingsEnvironment>['multilingual']

export const isCurrentLangPrimary = (multilingual: MultilingualContext): boolean => {
  if (!multilingual?.isEnabled) {
    return true
  }

  const currentLang = multilingual.siteLanguages.find(
    (lang) => lang.languageCode === multilingual.currentLanguage,
  )

  return currentLang?.isPrimaryLanguage ?? true
}

export const getFirstDayOfWeek = (locale: string) => {
  const firstDay = getWeekStartByLocale(locale)

  return firstDay
}
