import {
  Details,
  Reservation,
  Status,
  Reservee,
} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'
import addHours from 'date-fns/addHours'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {getNearestQuarterTime} from '../../utils/date'
import {setDataToLocalStorage, getDataFromLocalStorage} from '../../utils/dataCapsule'
import {goToReservationConfirmation, goToReservationDetails} from '../../utils/navigation'
import {getLogger} from '../../utils/getLogger'
import {DEFAULT_PARTY_SIZE} from '../../utils/partySize'
import {ReservationDetailsStorage} from '../../components/ReservationDetails/storage'

import {EDITOR_MOCK_RESERVATION_LOCATION_ID} from './getReservationLocationsMock'

export const RESERVATION_ID_MOCK = 'eb6fad5d-2f21-42d2-a330-84c4bb7df34d'

const CACHE_KEY = 'tr-reservation-mock'

export const handleReservationDataMock = (flowAPI: ControllerFlowAPI) => {
  return async ({
    details,
    timeZone,
    isManualApproval,
  }: {
    details: Details
    timeZone?: string | null
    isManualApproval?: boolean
  }) => {
    setDataToLocalStorage(flowAPI, CACHE_KEY, {
      id: RESERVATION_ID_MOCK,
      status: Status.HELD,
      details: {
        reservationLocationId: details.reservationLocationId,
        startDate: zonedTimeToUtc(details.startDate!, timeZone),
        endDate: addHours(zonedTimeToUtc(details.startDate!, timeZone), 2),
        partySize: details.partySize,
      },
    })

    if (isManualApproval) {
      return goToReservationDetails({
        flowAPI,
        timeZone,
        reservationData: {
          startDate: zonedTimeToUtc(details.startDate!, timeZone),
          partySize: details.partySize!,
          reservationLocationId: details.reservationLocationId!,
        },
      })
    }
    await goToReservationDetails({
      flowAPI,
      isTemplateView: true,
      reservationId: RESERVATION_ID_MOCK,
    })
  }
}

export const reserveReservationMock = (flowAPI: ControllerFlowAPI) => {
  return async (
    reservation: Reservation,
    reservee: Reservee,
    reservationLocation: ReservationLocation,
  ) => {
    const reservationFromCache = getReservationMock(flowAPI, Status.RESERVED)
    const logger = getLogger(flowAPI.bi!)

    // this is the only place where can get just created reservation
    logger.reservationCreated({
      isPreview: true,
      reservation: reservationFromCache,
      reservee,
      reservationLocation,
    })

    setDataToLocalStorage(flowAPI, CACHE_KEY, reservationFromCache)

    await goToReservationConfirmation(flowAPI, RESERVATION_ID_MOCK, true)
  }
}

export const getReservationMock = (
  flowAPI: ControllerFlowAPI,
  status?: Status,
  fallbackReservationLocationId?: string,
): Reservation => {
  const reservationFromCache = getDataFromLocalStorage<Reservation>(flowAPI, CACHE_KEY)
  if (reservationFromCache) {
    return {
      ...reservationFromCache,
      status: status ?? reservationFromCache.status,
      details: {
        ...reservationFromCache.details,
        startDate: new Date(reservationFromCache.details!.startDate!),
        endDate: new Date(reservationFromCache.details!.endDate!),
      },
    }
  } else {
    const date = getNearestQuarterTime(new Date())

    return {
      id: RESERVATION_ID_MOCK,
      status: status ?? Status.HELD,
      details: {
        reservationLocationId: fallbackReservationLocationId ?? EDITOR_MOCK_RESERVATION_LOCATION_ID,
        startDate: date,
        endDate: date,
        partySize: DEFAULT_PARTY_SIZE,
      },
    }
  }
}

export const getReservationDataQueryParamsMock = (
  flowAPI: ControllerFlowAPI,
  fallbackReservationLocationId?: string,
): ReservationDetailsStorage['reservationDataQueryParams'] => {
  const query = flowAPI.controllerConfig?.wixCodeApi?.location?.query
  return {
    startDate: query?.startDate || new Date().toISOString(),
    partySize: query?.partySize || DEFAULT_PARTY_SIZE.toString(),
    reservationLocationId: query?.reservationLocationId || fallbackReservationLocationId,
  }
}
