import {CreateControllerFn} from '@wix/yoshi-flow-editor'
import {createEventHandler} from '@wix/tpa-settings'

import {isTemplateView} from '../../utils/flowAPI'
import {SettingsEvents, SettingsEventsValues} from '../../utils/constants'

import {initReservationsStorage, mockReservationsStorage} from './storage'

const createController: CreateControllerFn = async ({flowAPI, controllerConfig}) => {
  const publicData = controllerConfig.config.publicData.COMPONENT || {}
  const handler = createEventHandler<SettingsEventsValues>(publicData)

  return {
    async pageReady() {
      handler.on(SettingsEvents.APPROVAL_TEXT_SETTINGS, (value) => {
        flowAPI.controllerConfig.setProps({approvalTextEditorState: value})
      })
      const isEditor = flowAPI.environment.isEditor
      const isPreview = flowAPI.environment.isPreview
      const isTemplate = isTemplateView(flowAPI)

      const shouldMockStorage = isEditor || isPreview || isTemplate

      const storage = shouldMockStorage
        ? mockReservationsStorage(flowAPI, controllerConfig)
        : initReservationsStorage(flowAPI, controllerConfig)

      flowAPI.controllerConfig.setProps(storage)

      await Promise.all([storage.getReservationLocations(), storage.getIsSiteBusinessPremium()])
    },
    updateConfig(_, config) {
      handler.notify(config.publicData.COMPONENT || {})
    },
  }
}

export default createController
