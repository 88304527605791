export async function retryOnCondition<T>({
  fetchData,
  checkCondition,
  maxAttempts = 3,
  delayMillis = 5000,
  errorMessage = 'Condition was not met within the allowed attempts',
}: {
  fetchData: () => Promise<T>
  checkCondition: (data: T) => boolean
  maxAttempts?: number
  delayMillis?: number
  errorMessage?: string
}): Promise<T> {
  let attempt = 0
  while (attempt < maxAttempts) {
    const data = await fetchData()
    if (!checkCondition(data)) {
      return data // Data meets the desired condition, return it
    }
    if (attempt < maxAttempts - 1) {
      await new Promise((resolve) => setTimeout(resolve, delayMillis)) // Wait for delay
    }
    attempt++
  }
  throw new Error(errorMessage)
}
