import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Details} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'

import {reservationsService} from '../../../services/reservationsService'
import {goToReservationDetails} from '../../../utils/navigation'
import {RequestStatus} from '../../../utils/wrapRequest'

export const handleReservationData = (flowAPI: ControllerFlowAPI) => {
  return async ({
    details,
    timeZone,
    isManualApproval,
  }: {
    details: Details
    timeZone: string | null
    isManualApproval: boolean
  }) => {
    const setProps = flowAPI.controllerConfig.setProps

    try {
      setProps({
        handleReservationDataStatus: RequestStatus.LOADING,
      })

      if (isManualApproval) {
        await goToReservationDetails({
          flowAPI,
          timeZone,
          reservationData: {
            startDate: zonedTimeToUtc(details.startDate!, timeZone),
            partySize: details.partySize!,
            reservationLocationId: details.reservationLocationId!,
          },
        })
      } else {
        const data = await reservationsService.holdReservation(flowAPI, {
          ...details,
          startDate: zonedTimeToUtc(details.startDate!, timeZone),
        })

        await goToReservationDetails({flowAPI, reservationId: data?.id})
      }
    } catch (err) {
      setProps({
        handleReservationDataStatus: RequestStatus.FAILED,
      })
    }
  }
}
