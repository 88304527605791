import {isEligible} from '@wix/ambassador-premium-features-manager-v1-feature/http'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export const getIsSiteBusinessPremium = async (flowAPI: ControllerFlowAPI) => {
  const {data} = await flowAPI.httpClient.request(
    isEligible({uniqueName: 'restaurants_table_reservations'}),
  )

  return data.isEligible ?? false
}
