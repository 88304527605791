import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export const setDataToMemoryCache = <T>(flowAPI: ControllerFlowAPI, key: string, data: T) => {
  try {
    flowAPI.controllerConfig.platformAPIs.storage.memory.setItem(key, JSON.stringify(data))
  } catch (e) {}
}

export const removeFromMemoryCache = (flowAPI: ControllerFlowAPI, key: string) => {
  try {
    flowAPI.controllerConfig.platformAPIs.storage.memory.removeItem(key)
  } catch (e) {}
}

export const getDataFromMemoryCache = <T>(flowAPI: ControllerFlowAPI, key: string): T | null => {
  try {
    const serializedData = flowAPI.controllerConfig.platformAPIs.storage.memory.getItem(key)

    if (serializedData) {
      return JSON.parse(serializedData) as T
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}

export const setDataToLocalStorage = <T>(
  flowAPI: ControllerFlowAPI,
  key: string,
  data: T,
  expirationMinutes?: number,
) => {
  try {
    const expiration = expirationMinutes
      ? new Date().getTime() + expirationMinutes * 60 * 1000
      : null
    const item = {
      value: data,
      expiration,
    }
    flowAPI.controllerConfig.platformAPIs.storage.local.setItem(key, JSON.stringify(item))
  } catch (e) {}
}

export const removeFromLocalStorage = (flowAPI: ControllerFlowAPI, key: string) => {
  try {
    flowAPI.controllerConfig.platformAPIs.storage.local.removeItem(key)
  } catch (e) {}
}

export const getDataFromLocalStorage = <T>(flowAPI: ControllerFlowAPI, key: string): T | null => {
  try {
    const serializedData = flowAPI.controllerConfig.platformAPIs.storage.local.getItem(key)
    if (serializedData) {
      const item = JSON.parse(serializedData)
      if (item.expiration && new Date().getTime() > item.expiration) {
        // Data expired
        removeFromLocalStorage(flowAPI, key)
        return null
      }
      return item.value as T
    } else {
      return null
    }
  } catch (e) {
    return null
  }
}
