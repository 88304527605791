import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

import {retryOnCondition} from './retryOnCondition'
import {EXPERIMENTS} from './constants'

export enum RequestStatus {
  DEFAULT = 'DEFAULT',
  LOADING = 'LOADING',
  RESOLVED = 'RESOLVED',
  FAILED = 'FAILED',
}

type WrapRequestFunc = <T, K extends unknown[]>(
  flowAPI: ControllerFlowAPI,
  method: (flowApi: ControllerFlowAPI, ...params: K) => Promise<T>,
  dataFieldName: string,
  statusFieldName: string,
  errorFieldName?: string,
  retryCondition?: (data: T) => boolean,
  maxAttempts?: number,
  delayMillis?: number,
) => (...params: K) => Promise<T | undefined>

export const wrapRequest: WrapRequestFunc =
  (
    flowAPI,
    method,
    dataFieldName,
    statusFieldName,
    errorFieldName,
    retryCondition,
    maxAttempts = 3,
    delayMillis = 5000,
  ) =>
  async (...params) => {
    const setProps = flowAPI.controllerConfig.setProps
    const isPaymentRuleFlowEnabled = flowAPI.experiments.enabled(
      EXPERIMENTS.isPaymentRuleFlowEnabled,
    )

    try {
      setProps({
        [statusFieldName]: RequestStatus.LOADING,
      })

      // Wrapper function to include retry logic
      const fetchDataWithRetry = async () => {
        if (isPaymentRuleFlowEnabled && retryCondition) {
          return retryOnCondition({
            fetchData: () => method(flowAPI, ...params),
            checkCondition: retryCondition,
            maxAttempts,
            delayMillis,
          })
        } else {
          return method(flowAPI, ...params)
        }
      }

      const data = await fetchDataWithRetry()
      setProps({
        [statusFieldName]: RequestStatus.RESOLVED,
        [dataFieldName]: data,
        ...(errorFieldName ? {[errorFieldName]: undefined} : {}),
      })

      return data
    } catch (err) {
      setProps({
        [statusFieldName]: RequestStatus.FAILED,
        ...(errorFieldName ? {[errorFieldName]: (err as any)?.response?.data} : {}),
      })
    }
  }
