import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservation} from '@wix/ambassador-table-reservations-v1-reservation/types'
import omitBy from 'lodash/omitBy'

import {APP_DEF_ID, AppPage, ECOM_APP_DEF_ID} from './constants'
import {getUrlParamsFromObject} from './queryParams'

type IsoDateString = string

type ReservationsParams = {
  reservationLocationId?: string
  startDate?: IsoDateString
  partySize?: string
  isTemplateView?: string
}

type ReservationDetailsParams = {isTemplateView?: string} & (
  | {
      reservationId: string
    }
  | {
      startDate: string
      partySize: string
      reservationLocationId: string
    }
)

type ReservationConfirmationParams = {
  reservationId: string
  orderId?: string
  isTemplateView?: string
}

type AppPageToParamsMap = {
  [AppPage.Reservations]?: ReservationsParams
  [AppPage.ReservationDetails]: ReservationDetailsParams
  [AppPage.ReservationConfirmation]: ReservationConfirmationParams
}

type AppPageToParams<T extends AppPage> = AppPageToParamsMap[T]

export async function getRelativeUrlWithParams<T extends AppPage>(
  page: T,
  params: AppPageToParams<T>,
  flowAPI: ControllerFlowAPI,
) {
  const {relativeUrl} = await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
    sectionId: page,
    appDefinitionId: APP_DEF_ID,
  })

  const preparedParams = getUrlParamsFromObject(params || {})

  return `${relativeUrl}?${preparedParams}`
}

export async function goToPage<T extends AppPage>(
  page: T,
  params: AppPageToParams<T>,
  flowAPI: ControllerFlowAPI,
) {
  const url = await getRelativeUrlWithParams(page, params, flowAPI)

  flowAPI.controllerConfig.wixCodeApi.location.to?.(url)
}

type ReservationDataOptional = Pick<
  Exclude<Reservation['details'], undefined>,
  'reservationLocationId' | 'startDate' | 'partySize'
>

type ReservationData = {
  [P in keyof ReservationDataOptional]-?: NonNullable<ReservationDataOptional[P]>
}

export const goToNewReservation = ({
  flowAPI,
  reservationData,
  isTemplateView,
}: {
  flowAPI: ControllerFlowAPI
  timeZone?: string | null
  reservationData?: ReservationDataOptional
  isTemplateView?: boolean
}) =>
  goToPage(
    AppPage.Reservations,
    {
      reservationLocationId: reservationData?.reservationLocationId ?? undefined,
      startDate: reservationData?.startDate?.toISOString(),
      partySize: reservationData?.partySize?.toString(),
      ...(isTemplateView ? {isTemplateView: 'true'} : {}),
    },
    flowAPI,
  )

export const goToReservationDetails = ({
  flowAPI,
  reservationData,
  reservationId,
  isTemplateView,
}: {
  flowAPI: ControllerFlowAPI
  timeZone?: string | null
  isTemplateView?: boolean
  reservationId?: string | null
  reservationData?: ReservationData
}) => {
  if (reservationId) {
    return goToPage(
      AppPage.ReservationDetails,
      {reservationId, ...(isTemplateView ? {isTemplateView: 'true'} : {})},
      flowAPI,
    )
  }

  if (reservationData) {
    return goToPage(
      AppPage.ReservationDetails,
      {
        reservationLocationId: reservationData.reservationLocationId,
        startDate: reservationData.startDate.toISOString(),
        partySize: reservationData.partySize.toString(),
        ...(isTemplateView ? {isTemplateView: 'true'} : {}),
      },
      flowAPI,
    )
  }
}

export const goToReservationConfirmation = (
  flowAPI: ControllerFlowAPI,
  reservationId?: string | null,
  isTemplateView?: boolean,
  orderId?: string,
) => {
  if (reservationId) {
    return goToPage(
      AppPage.ReservationConfirmation,
      {reservationId, orderId, ...(isTemplateView ? {isTemplateView: 'true'} : {})},
      flowAPI,
    )
  }
}

export const navigateToCheckoutPage = async (
  flowAPI: ControllerFlowAPI,
  checkoutId: string,
  reservationId: string,
) => {
  const wixCodeApi = flowAPI.controllerConfig.wixCodeApi
  const ecomApi = await wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID)

  const baseUrl = wixCodeApi.location.baseUrl

  const relativeUrlWithParams = await getRelativeUrlWithParams(
    AppPage.ReservationConfirmation,
    {orderId: '{orderId}', reservationId},
    flowAPI,
  )

  const query = flowAPI.controllerConfig?.wixCodeApi?.location?.query ?? {}

  // ecom converts successUrl to json, so we need to replace double quotes
  // with url encoded %22 to avoid JSON parsing errors
  for (const key in query) {
    if (query.hasOwnProperty(key)) {
      query[key] = query[key].replaceAll('"', '%22')
    }
  }

  const queryParams = getUrlParamsFromObject(query)

  // successUrl is stored in checkout page query params
  // so we need to encode "&" to "%26" to avoid URL parsing errors
  const successUrl = `${baseUrl}${relativeUrlWithParams}%26${queryParams}`.replaceAll('&', '%26')

  return ecomApi.navigate.toCheckout({
    checkoutId,
    disableContinueShopping: true,
    successUrl,
  })
}

export const openBmLocationSettingsPage = (metaSiteId: string) =>
  window.open(`https://manage.wix.com/dashboard/${metaSiteId}/settings/business-info`)
