import {
  ReservationLocation,
  Unit,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

import {defaultBusinessSchedule} from '../../utils/businessSchedule'
import {reservationLocationsService} from '../../services/reservationLocationsService'

export const EDITOR_MOCK_RESERVATION_LOCATION_ID = 'editor-mock-reservation-location-id'

export const getReservationLocationsMock = async (
  flowAPI: ControllerFlowAPI,
): Promise<ReservationLocation[]> => {
  let reservationLocationsFromApi: ReservationLocation[] = []

  try {
    reservationLocationsFromApi = await reservationLocationsService.getReservationLocations(flowAPI)
  } catch (e) {}

  const atLeastOneLocationHasEnabledOnlineReservation = reservationLocationsFromApi?.some(
    (reservationLocation) =>
      reservationLocation?.configuration?.onlineReservations?.onlineReservationsEnabled,
  )

  return reservationLocationsFromApi?.length
    ? atLeastOneLocationHasEnabledOnlineReservation
      ? reservationLocationsFromApi
      : reservationLocationsFromApi.map((r) => ({
          ...r,
          configuration: {
            ...r.configuration,
            onlineReservations: {
              ...r.configuration?.onlineReservations,
              onlineReservationsEnabled: true,
            },
          },
        }))
    : [reservationLocation]
}

const reservationLocation: ReservationLocation = {
  id: EDITOR_MOCK_RESERVATION_LOCATION_ID,
  location: {
    id: 'id',
    name: 'San Francisco',
    timeZone: 'America/Los_Angeles',
    address: {
      country: 'US',
      subdivision: 'CA',
      city: 'San Francisco',
      formattedAddress: 'San Francisco, CA, USA',
      geocode: {
        latitude: 37.7749295,
        longitude: -122.4194155,
      },
    },
  },
  configuration: {
    onlineReservations: {
      partiesSize: {
        min: 1,
        max: 6,
      },
      minimumReservationNotice: {
        number: 30,
        unit: Unit.MINUTES,
      },
      turnoverTimeRules: [],
      onlineReservationsEnabled: true,
      businessSchedule: defaultBusinessSchedule,
    },
    myReservationsFields: [],
  },
}
