import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export const getRegionalSettings = (flowAPI: ControllerFlowAPI) => {
  const isMultilingualEnabled = flowAPI.controllerConfig.wixCodeApi.window.multilingual.isEnabled

  let locale =
    flowAPI.controllerConfig.wixCodeApi.site.regionalSettings ??
    flowAPI.controllerConfig.wixCodeApi.site.language

  if (isMultilingualEnabled) {
    const currentLanguage = flowAPI.controllerConfig.wixCodeApi.window.multilingual.currentLanguage
    const currentLocale =
      flowAPI.controllerConfig.wixCodeApi.window.multilingual.siteLanguages.find(
        ({languageCode}) => languageCode === currentLanguage,
      )?.locale

    if (currentLocale) {
      locale = currentLocale
    }
  }

  return locale
}

export interface Instance {
  metaSiteId?: string
  appDefId?: string
  biToken?: string
  instanceId?: string
  aid?: string
  siteOwnerId?: string
  siteIsTemplate?: boolean
  uid?: string
}

export const getInstance = (flowApi: ControllerFlowAPI): Instance => {
  const signedInstance = flowApi.controllerConfig.appParams.instance
  try {
    return JSON.parse(atob(signedInstance.split('.')[1]))
  } catch (e) {
    return {}
  }
}

export const isTemplateView = (flowApi: ControllerFlowAPI) => {
  const instance = getInstance(flowApi)
  return instance?.siteIsTemplate ?? false
}
